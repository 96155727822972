/* ==================
    top
        topページ用のスタイル

    INDEX
        - object
        - login
        - banner
        - news
        - mailmagazine
        - members menu
        - support
================== */

/* ==================
    object
================== */
.top-obj {
    position: absolute;
    top: 0;
    width: 100%;
}

// トップ画面最上部の画像たち
.obj {
    position: absolute;
    transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transform: translateY(-100vw) translateX(0);

    &.js-active {
        transform: translateY(0);
    }

    &.js-right {
        transform: translateX(0);
    }

    &.js-left {
        transform: translateX(0);
    }

    // 赤い花 左上
    &01 {
        width: 20vw;
        left: 6vw;
        top: 3vw;
        z-index: 1;
    }

    // 赤い花 右下１
    &10 {
        width: 13vw;
        right: 8vw;
        top: 45vw;
        z-index: 100;
    }

    // 赤い花 右下２
    &11 {
        width: 11vw;
        right: 1vw;
        top: 52vw;
        z-index: 101;
    }

    // 赤い花 右下３
    &12 {
        width: 8vw;
        right: 12vw;
        top: 66vw;
        z-index: 102;
    }

    // 赤い花 左下
    &13 {
        width: 12vw;
        left: 1vw;
        top: 53vw;
        z-index: 1;
    }

    // 四角
    &02 {
        width: 14vw;
        right: 6vw;
        top: 55vw;
        z-index: 1;
    }

    // 赤い四角
    &03 {
        width: 15vw;
        right: -5vw;
        top: 10vw;
        z-index: 1;
    }

    // ひし形のぴろぴろ
    &04 {
        width: 9vw;
        right: 11vw;
        top: 0;
        z-index: 100;
    }

    // 赤枠のひし形
    &05 {
        width: 15vw;
        right: 0;
        top: 63vw;
        z-index: 1;
    }

    // 黒い丸
    &06 {
        width: 18vw;
        left: 0;
        top: 19vw;
        z-index: 1;
    }

    // 赤い枠の大きいひし形
    &07 {
        width: 27vw;
        left: 1vw;
        top: 32vw;
        z-index: 1;
    }

    // 黒い花 
    &08 {
        width: 20vw;
        left: 3vw;
        top: 58vw;
        z-index: 1;
    }

    // 黒いチェックの丸
    &09 {
        width: 30vw;
        right: -11vw;
        top: 24vw;
        z-index: 1;
    }

    // 雲 左
    &14 {
        width: 35vw;
        left: 0;
        top: 56vw;
        z-index: 0;
        transition: transform 5s;
        transform: translateX(-100%);
    }

    // 雲 右
    &15 {
        width: 29vw;
        right: 0;
        top: 20vw;
        z-index: 0;
        transition: transform 5s;
        transform: translateX(100%);
    }
}


/* ==================
    login
================== */
// ログインなどのトップメニュー
.login-area {
    position: relative;
}

.btn-area {
    position: relative;
    z-index: 2;
    transition: 0.5s;
    opacity: 0;

    &.js-active {
        opacity: 1;
    }
}

// 帯
.obi {
    $IMG_SIZE: 80px;

    position: absolute;
    bottom: -20%;
    left: -50%;
    width: 200%;
    background-image: url($IMG+'top/obi.png');
    background-position: center;
    background-size: $IMG_SIZE;
    height: 100vh;
    z-index: 999;
    transition: 0.8s cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &.js-active {
        height: 140%;
        z-index: 1;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        width: 100%;
        height: 12px;
        background-repeat: repeat-x;
        background-size: $IMG_SIZE;
        background-position: center;
    }

    &:before {
        top: -8px;
        background-image: url($IMG+'top/obi_top.png');
    }

    &:after {
        bottom: -8px;
        background-image: url($IMG+'top/obi_bottom.png');
    }
}


/* ==================
    banner
================== */
.banner-area {
    margin: 0 0 25px;
}


/* ==================
    news
================== */
.top-news-area {
    background-color: $C_BLACK;
    padding-top: 50px;

    &.type-2 {
        padding-top: 65px;
    }
}

.more-wrap {
    position: relative;
    height: 35px;

    .more {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 2;
    }
}


/* ==================
    mailmagazine
================== */
.top-mailmagazine-area {
    margin-top: 60px;
}


/* ==================
    members menu
================== */
.top-memners-menu-area {
    background: $C_GRAY;
}

.members-menu-title {
    padding: 30px 10px 0;
}

.members-menu-list {
    position: relative;
    height: 140vw;
    margin-top: 17vw;
    margin-bottom: 5vh;

    &__list {
        position: absolute;

        a {
            display: block;
        }

        // 密着!社長室
        &.type-staffmovie {
            width: 90%;
            top: -10vw;
            right: 0;
            left: 0;
            z-index: 3;
            margin: auto;
        }

        // 秘書の目
        &.type-blog {
            width: 80%;
            top: 0;
            right: 5%;
            z-index: 2;
        }

        // おみくじ
        &.type-fortune {
            width: 33%;
            top: 7%;
            left: 4%;
            z-index: 1;
        }

        // お絵描き処
        &.type-illust {
            width: 60%;
            top: 12%;
            right: 2%;
        }

        // ラヂオ
        &.type-radio {
            width: 40%;
            top: 44%;
            left: 22%;
            z-index: 5;
        }

        // 個人日誌
        &.type-memory {
            width: 44%;
            top: 45%;
            right: 3%;
            z-index: 2;
        }

        // 社員証
        &.type-card {
            width: 57%;
            top: 69%;
            left: 3%;
            z-index: 4;
        }

        // 代表取締役訓示
        &.type-movie {
            width: 60%;
            top: 71%;
            right: 5%;
            z-index: 3;
        }

         // 社長裏日誌
         &.type-reolblog {
            width: 70%;
            top: 92%;
            right: 16%;
            z-index: 2;
        }
    }
}

.top-copyright {
    display: block;
    text-align: center;
    color: $C_WHITE;
    padding: 30px 0;
    font-size: 1.2rem;
}


/* ==================
    support
================== */
.support {
    &-area {
        background-color: $C_BLACK;
    }

    &-list {
        @include clearfix();
        max-width: 315px;
        margin: auto;
        padding: 20px 0;

        &__item {
            width: 50%;
            float: left;
            border-left: 3px solid $C_RED;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            a {
                @extend %x-small;
                display: block;
                padding: 3px 10px;
                color: $C_WHITE;
            }
        }
    }
}
