/* ==================
    base
        タグに直接かけるスタイルなど
================== */
* {
    box-sizing: border-box;
}

body {
    background-image: url($IMG+"parts/bg.png");
    background-size: 100%;
    background-repeat: repeat-y;
    min-height: 100%;
    height: auto !important;
    height: 100%;
    position: relative;
}

html,
body {
    font-size: 62.5%; // base font size = 10px
    line-height: 1.4em;
    font-family: $MAIN_FONT_FAMILY;
    height: 100%;

    // 文字のアンチエイリアス
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: unset;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
    body,
    html {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

// iPhone、iOSでのデフォルトスタイルをリセット */

input[type="text"],
textarea,
input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="email"],
select {
    appearance: none;
}

input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="email"] {
    border-radius: 0;
    border: none;
    box-sizing: border-box;
    background-color: transparent;

    &:-webkit-search-decoration {
      display: none;
    }
    &:focus {
      outline-offset: -2px;
    }
}


input[type="text"],
textarea,
input[type="email"] {
    width: 100%;
    padding: 5px;
    border: 1px solid $C_GRAY;
}

input[type="radio"] {
    vertical-align: sub;
}

select {
    padding: 6px 8px;
    background: transparent;
    border-color: $C_GRAY;
    margin-right: 5px;
    margin-left: 0;
}

// 生年月日のセレクト
.birthday-select {
    select {
        margin-right: 5px;
        margin-left: 0;

        // 二つ以上は左に余白をとる
        &:nth-child(n + 2) {
            margin-left: 5px;
        }
    }
}

img {
    width: 100%;
    height: auto;
}

a {
    color: $C_FONT;
    text-decoration: none;
}

// デフォルトの文字
p,
a,
span,
li,
dt,
dd,
.aem-post,
.enq,
input[type="submit"],
input[type="button"],
.text {
    color: $C_BLACK;
    font-size: 1.2rem;
    line-height: 1.8;
    word-wrap: break-word;
}

// .aem-post {
//     color: $C_BLACK;
//     font-size: 1.2rem;
//     line-height: 1.8;
//     word-wrap: break-word;
// }

a,
span {
    display: inline;
}

table {
    table-layout: fixed;
    width: 100%;
}

th {
    vertical-align: top;
}

// movie
img.eviry-poster {
    margin-left: 0 !important;
}
div.eviry-baseplayer {
    margin: auto;
}

// reCAPCHAR
.grecaptcha-badge {
    bottom: 74px !important;
}
