/* ==================
    header
================== */
.header {
    padding: 12px 0 10px;
    background-image: url($IMG+'parts/top_bg.png');
    background-repeat: repeat-x;
    background-size: 80px;
    background-position: top center;

    &-logo {
        width: 120px;
        height: auto;
        display: block;
        margin: auto;

        a {
            display: block;
        }
    }
}

// トップのヘッダー
.top-header {
    position: relative;
    padding: 15vw 0 18vw;

    &-logo {
        width: 34vw;
        margin: auto;
        opacity: 0;
        transition: 1s;

        &.js-active {
            opacity: 1;
        }
    }

    .logo-title {
        margin-bottom: 10px;
    }
}
