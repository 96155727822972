// ==================
//    mixin
// ==================

// -------------------------
// フロートの解除
// -------------------------
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}
