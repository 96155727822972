/* ==================
    format
        全画面共通で使いそうなformat.php内で定義しているスタイル

    INDEX
        - title
        - layout
        - font
        - color
        - border
        - margin
        - btn
        - list
        - pager
        - accordion
        - checkbox

================== */

/* ==================
    title
================== */
// 大見出し
h3:not([class]),
.main-title {
    @extend %f-jp;
    position: relative;
    font-size: 1.8rem;
    line-height: 1.6;
    text-align: center;
    border: 1px solid $C_BLACK;
    width: 80%;
    background-color: $C_WHITE;
    margin: 6px auto 30px;
    padding: 4px 10px;
    word-wrap: break-word;

    &:before {
        content: "";
        background-color: $C_BLACK;
        width: 200%;
        height: 6px;
        position: absolute;
        left: -50%;
        top: calc(50% - 7px);
        box-shadow: 0px 8px 0px 1px $C_RED;
        z-index: -1;
    }
}

// 中見出し
h4:not([class]),
.sub-title {
    position: relative;
    font-size: 1.3rem;
    font-weight: bold;
    line-height: 1.7;
    margin-bottom: 10px;
    border-bottom: 1px solid $C_BLACK;
    padding: 0 4px 10px;
    margin-top: 4px;
    word-wrap: break-word;

    &:before {
        content: "";
        width: calc(100% - 8px);
        height: 4px;
        border-right: 4px solid $C_BLACK;
        border-left: 4px solid $C_BLACK;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

// 左にブロックがある見出し
.block-title {
    background-color: $C_BLACK;
    background-image: url($IMG+'parts/sub_mark.png');
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    color: $C_WHITE;;
    font-size: 1.2rem;
    padding: 10px 0 10px 40px;
    font-weight: bold;
    margin-bottom: 10px;
    word-wrap: break-word;
}

// ２重下線がある見出し
.border-title {
    border-bottom: 3px double $C_GRAY;
    box-shadow: 0px 1px 0px 0px $C_BLACK;
    padding-bottom: 6px;
    font-size: 1.4rem;
    margin-bottom: 10px;
    line-height: 1.6;
    word-wrap: break-word;
}


/* ==================
    layout
================== */
.al-c {
    text-align: center !important;
}

.al-r {
    text-align: right !important;
}

.al-l {
    text-align: left !important;
}

.fr {
    float: right !important;
}

.fl {
    float: left !important;
}

.cf {
    @include clearfix();
}


/* ==================
    font
================== */
%x-small {
    font-size: 1rem;
}

.x-small {
    @extend %x-small;
}

%small {
    font-size: 1.2rem;
}

.small {
    @extend %small;
}

%large {
    font-size: 1.5rem;
}

.large {
    @extend %large;
}

%x-large {
    font-size: 2rem;
}

.x-large {
    @extend %x-large;
}

%bold {
    font-weight: bold;
}

.bold {
    @extend %bold;
}

%attention {
    font-style: italic;
}

.attention {
    @extend %attention;
    color: $C_RED;
}

%link {
    color: $C_LINK_FONT;
    text-decoration: none;
    border-bottom: 1px solid $C_LINK_FONT;
    font-size: 1em;
}

.link {
    @extend %link;
}

%f-jp {
    font-family: $JP_FONT_FAMILY;
}

.f-jp {
    @extend %f-jp;
}

%f-en-serif {
    font-family: $EN_SERIF_FONT_FAMILY;
}

.f-en-serif {
    @extend %f-en-serif;
}

%f-en {
    font-family: $EN_FONT_FAMILY;
}

.f-en {
    @extend %f-en;
}

.highlight {
    > span {
        font-weight: bold;
        background-color: $C_RED;
        color: $C_WHITE;
    }

    &.type-black {
        > span {
            background-color: $C_WHITE;
            color: $C_BLACK;
        }
    }
}


/* ==================
    color
================== */
.c-red {
    color: $C_RED !important;
}

.c-blue {
    color: $C_BLUE !important;
}

.c-yellow {
    color: $C_YELLOW !important;
}

.c-green {
    color: $C_GREEN !important;
}

.c-pink {
    color: $C_PINK !important;
}

.c-purper {
    color: $C_PURPER !important;
}

.c-white {
    color: $C_WHITE !important;
}

.c-black {
    color: $C_BLACK !important;
}


/* ==================
    border
================== */
hr {
    // 黒直線
    &:not([class]),
    &.black {
        border: none;
        border-top: 2px solid $C_BLACK;
    }

    // 赤直線
    &.red {
        border: none;
        border-top: 2px solid $C_RED;
    }

    // 白黒の帯
    &.band {
        position: relative;
        left: -50%;
        border: none;
        background-color: $C_WHITE;
        background-image: url($IMG+'parts/pattern_1.png');
        background-size: contain;
        width: 200%;
        height: 30px;
    }
}


/* ==================
    margin
================== */
// margin 0〜120まで5px刻み
@for $i from 0 through 24 {
    // margin-top
    .mt-#{$i * 5},
    .mt#{$i * 5} {
        margin-top: #{$i * 5}px !important;
    }

    // margin-bottom
    .mb-#{$i * 5},
    .mb#{$i * 5} {
        margin-bottom: #{$i * 5}px !important;
    }

    // margin-right
    .mr-#{$i * 5},
    .mr#{$i * 5} {
        margin-right: #{$i * 5}px !important;
    }

    // margin-left
    .ml-#{$i * 5},
    .ml#{$i * 5} {
        margin-left: #{$i * 5}px !important;
    }
}

// marginキャンセル用
.mt-00,
.mt00 {
    margin-top: 0 !important;
}

.mb-00,
.mb00 {
    margin-bottom: 0 !important;
}


/* ==================
    btn
================== */
// 基本的なボタン
%base-btn {
    position: relative;
    text-align: center;
    line-height: 1.4;
    margin: 5px 0;
    background-color: $C_BLACK;
    color: $C_WHITE;
    padding: 18px 10px;

    a {
        color: $C_WHITE;
    }

    input {
        color: $C_WHITE;
        line-height: 1.4;
        display: block;
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 99;
    }

    &:before {
        content: "";
        width: calc(100% - 18px);
        border-style: solid;
        border-width: 6px;
        display: block;
        top: 2px;
        left: 3px;
        position: absolute;
        border-color: $C_WHITE transparent;
        height: calc(100% - 16px);
    }

    &:after {
        content: "";
        width: calc(100% - 16px);
        border-style: solid;
        border-width: 6px;
        display: block;
        top: 3px;
        left: 2px;
        position: absolute;
        border-color: transparent $C_WHITE;
        height: calc(100% - 18px);
    }

    // ボタン打ち消し
    &.btn-none {
        background: $C_GRAY;
        //pointer-events: none; // クリック、タップ禁止
    }

    &.btn-dummy {
        background-color: rgba(0,0,0,0.3);
        color: #000;
        pointer-events: none; // クリック、タップ禁止
    }
}

// 目立つボタン
%pickup-btn {
    border-radius: 30px;
    background-color: $C_RED;
    background-image: url($IMG+'parts/circle_red.png');
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    border: 2px solid $C_BLACK;
    border-left: 1px solid $C_BLACK;
    padding: 10px;
    text-align: center;
    color: $C_WHITE;
    margin: 10px 0;
    transition: 0.3s;

    input {
        color: $C_WHITE;
        line-height: 1.4;
        display: block;
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 99;
    }

    &.btn-none {
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(1);
    }

    &.btn-fin {
        opacity: 0.7;
        pointer-events: none;
        filter: grayscale(1);
    }
}

// selectボタン
%select-btn {
    border-radius: 30px;
    background-color: $C_WHITE;
    background-image: url($IMG+'parts/circle_black.png');
    background-position: right;
    background-size: contain;
    background-repeat: no-repeat;
    border: 2px solid $C_BLACK;
    border-right: 1px solid $C_BLACK;
    padding: 10px;
    text-align: center;
    color: $C_WHITE;
    margin: 10px 0;
    transition: 0.3s;
    color: $C_BLACK;
    font-family: $JP_FONT_FAMILY;

    input {
        color: $C_WHITE;
        line-height: 1.4;
        display: block;
        text-align: center;
        width: 100%;
        position: relative;
        z-index: 99;
    }

    &.btn-none {
        opacity: 0.5;
        pointer-events: none;
        filter: grayscale(1);
    }

    &.btn-fin {
        opacity: 0.7;
        pointer-events: none;
        filter: grayscale(1);
    }
}


// 画面いっぱいの大きなボタン
input.btn-80,
.btn-80 {
    @extend %base-btn;
    width: 80%;
    display: block;
    margin: 10px auto;
}

// 画面の半分の大きさ、二つ並べられるボタン
input.btn-50,
.btn-50 {
    @extend %base-btn;
    width: 49.4%;
    display: inline-block;
    vertical-align: top;
}

// 目立つボタン
input.btn-100,
.btn-100 {
    @extend %pickup-btn;
    position: relative;
    width: 80%;
    display: block;
    margin: 10px auto;
}

// セレクトボタン
.select-btn {
    @extend %select-btn;
    display: block;
    margin: 10px auto 25px;
    position: relative;
    padding: 13px 17px;
    font-size: 13px;
    text-align: left;

    &:before {
        content: "";
        position: absolute;
        top: 11px;
        right: 13px;
        bottom: 0;
        border-style: solid;
        border-width: 8px 6px;
        border-color: $C_WHITE transparent transparent transparent;
        width: 0;
        height: 0;
        margin: auto;
    }

    select {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 45px;
        margin: auto;
        opacity: 0;
    }
}


/* ==================
    list
================== */
// カテゴリ
.category-list {
    position: relative;
    padding: 0 20px;
    margin-bottom: 30px;
    text-align: center;

    &:before,
    &:after {
        content: "";
        width: 15px;
        height: 44px;
        display: block;
        background-size: 100%;
        background-repeat: no-repeat;
        position: absolute;
        top: 0;
    }

    &:before {
        background-image: url($IMG+"parts/cate_left.png");
        left: 0;
    }

    &:after {
        background-image: url($IMG+"parts/cate_right.png");
        right: 0;
    }

    &__item {
        @extend %f-en;
        display: inline-block;
        line-height: 1;
        font-size: 1.7rem;
        margin-top: 3px;

        &:nth-child(-n+3) {
            width: 30%;
            text-align: center;
         }

         &:nth-child(n+4) {
            width: 35%;
            text-align: center;
         }

        a {
            @extend %f-en;
            line-height: 1;
            font-size: 1.7rem;
            padding: 0 3px;
        }

        &.current {
            background: $C_RED;
            color: $C_WHITE;
            font-weight: bold;
        }
    }
}

// 黒背景の基本的なリスト
.list {
    &__item {
        @include clearfix();
        padding: 15px 0;
        position: relative;
        background: $C_BLACK;
        margin-bottom: 2px;

        &:before {
            content: "";
            background: $C_BLACK;
            width: 200%;
            height: 100%;
            position: absolute;
            top: 0;
            left: -50%;
            z-index: -1;
            border-bottom: 2px solid $C_WHITE;
        }

        a {
            @include clearfix();
            display: block;
            color: $C_WHITE;
        }

        &__time {
            @extend %f-en-serif;
            color: $C_WHITE;
            font-size: 1.2rem;
            font-weight: bold;
            display: inline-block;

            span {
                color: $C_WHITE;

                &:first-child {
                    background-color: $C_RED;
                    padding: 2px 1px;
                }

                &:last-child {
                    font-size: 10px;
                }
            }
        }

        &__new {
            display: inline-block;
            width: 50px;
            margin-left: 3px;
        }

        &__content {
            font-size: 1.4rem;
            font-weight: bold;
            word-wrap: break-word;
            margin-top: 8px;
            color: $C_WHITE;
            text-decoration: underline;
        }

        .l-category {
            color: $C_WHITE;

            &::before {
                color: $C_WHITE;
                content: "｛";
                font-weight: bold;
                padding-right: 3px;
            }

            &::after {
                color: $C_WHITE;
                content: "｝";
                font-weight: bold;
                padding-left: 3px;
            }
        }
    }
}

// サムネ付きリスト
.thumb-list {
    @include clearfix();
    color: $C_BLACK;

    &__item {
        display: inline-block;
        width: 47%;
        margin-bottom: 20px;
        vertical-align: top;

        &:nth-child(2n) {
            margin-left: 4%;
        }

        &__img {
            border: 2px solid $C_RED;
            outline: 2px solid $C_BLACK;
        }

        &__time {
            @extend %f-en-serif;
            font-size: 1.2rem;
            font-weight: bold;
            display: inline-block;

            span {
                &:first-child {
                    color: $C_WHITE;
                    background-color: $C_RED;
                    padding: 2px 1px;
                }

                &:last-child {
                    font-size: 10px;
                }
            }
        }

        &__content {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }

    &.column-4 &__item {
        width: 21%;
        padding-top: 20%;
        margin-bottom: 5%;
        border: 2px solid $C_RED;
        outline: 2px solid $C_BLACK;
        background-size: cover;
        background-position: center;
        display: block;
        float: left;
        position: relative;

        &:nth-child(2n) {
            margin-left: 0;
        }

        &:not(:nth-child(4n)) {
            margin-right: 5%;
        }

        a {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

// サムネ付きリスト(ギャラリー)
.gallery-list__item__img {
    position: relative;
    width: 100%;
    padding-top: 74.5%;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 8px;
}

// 丸いサムネ付きリスト
.circle-list {
    &__item {
        position: relative;
        border-bottom: 3px double #888;
        box-shadow: 0px 1px 0px 0px $C_BLACK;
        min-height: 20vw;

        a {
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 30vw;
            padding: 20px 0;
        }

        &__time {
            @extend %f-en-serif;
            position: absolute;
            top: -4px;
            left: -4px;
            width: 10vw;
            padding-top: 10vw;
            background-color: $C_RED;
            overflow: hidden;

            &:before {
                content: "";
                width: 0.5px;
                height: 200%;
                background: rgba($C_WHITE, 0.5);
                position: absolute;
                top: -50%;
                left: -1px;
                transform: rotate(45deg);
                right: 0;
                margin: auto;
            }

            span {
                color: $C_WHITE;
                position: absolute;
                font-size: 3vw;

                &:first-child {
                    top: -2px;
                    left: 10%;
                }

                &:last-child {
                    bottom: -2px;
                    right: 10%;
                }
            }
        }

        &__img {
            width: 20vw;
            height: 20vw;
            border-radius: 50%;
            border: 2px solid $C_RED;
            box-shadow: 0px 0px 0px 2px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: 150%;
            position: relative;
        }

        &__left {
            width: 25vw;
        }

        &__right {
            flex: 2;
            position: relative;
        }

        &__new {
            width: 50px;
            position: absolute;
            top: -10px;
        }

        &__content {
            font-size: 1.4rem;
            font-weight: bold;
        }
    }
}


// １カラムのボックス
.box-list {
    &__item {
        @extend %f-en-serif;

        &:not(:first-child) {
            margin-top: 35px;
        }
    }

    &__time {
        @extend %f-en-serif;
        font-size: 1.4rem;
        margin-left: 4px;
    }

    &__title {
        line-height: 1.3;
    }
}

// 日付
.l-time {
    @extend %f-en-serif;
    font-size: 1.4rem;
    margin-left: 4px;

    span {
        font-size: 1.0rem;
    }

    &.red {
        span {
            font-size: 1.4rem;

            &:first-child {
                color: $C_WHITE;
                background-color: $C_RED;
                padding: 2px 1px;
            }

            &:last-child {
                font-size: 10px;
            }
        }
    }
}

// カテゴリー
.l-category {
    @extend %f-en-serif;
    font-size: 1.2rem;
    margin-left: 6px;

    &:before {
        content: "｛";
        font-weight: bold;
        padding-right: 3px;
    }

    &:after {
        content: "｝";
        font-weight: bold;
        padding-left: 3px;
    }
}

.contants-category-logo {
    width: 80px;
    vertical-align: top;
    margin-right: 3px;
}

// もっとみる
.more {
    display: block;
    width: 135px;
    margin: 20px auto;
}

/* ==================
    pager
================== */
.pager {
    @include clearfix();
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
    position: relative;

    .btn-back {
        background-image: url($IMG+'parts/page_left.png');
        left: 0;
    }

    .btn-list {
        display: inline-block;
        background-image: url($IMG+"parts/tolist.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        width: 200px;
        height: 75px;
    }

    .btn-next {
        background-image: url($IMG+'parts/page_right.png');
        right: 0;
    }

    .btn-num {
        &__item {
            @extend %f-en;
            width: 20px;
            display: inline-block;
            margin: 0 2px;
            font-size: 1.8rem;
            line-height: 1.3;

            &.current {
                background-color: $C_RED;
                color: $C_WHITE;
            }
        }
    }

    .btn-back,
    .btn-next {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 35px;
        height: 20px;
        background-size: contain;
        background-repeat: no-repeat;
    }

    // ボタン打ち消し
    .btn-back,
    .btn-list,
    .btn-next {
        &.btn-none {
            opacity: 0.3;
            pointer-events: none;
        }
    }
}


/* ==================
    accordion
================== */
.ac-menu {
    margin: 10px 0;

    > dt {
        background: $C_WHITE;
        color: $C_FONT;
        border: 1px solid $C_BLACK;
        font-weight: bold;
        padding: 10px 50px 10px 20px;
        position: relative;
        left: -20px;
        box-shadow: 2px 2px $C_BLACK;
        width: calc(100% + 20px);

        &:not(:first-child) {
            margin-top: 12px;
        }

        &:after {
            content: "";
            position: absolute;
            background-image: url($IMG+'parts/open.png');
            top: 0;
            right: 0;
            width: 40px;
            height: 30px;
            background-size: contain;
            background-repeat: no-repeat;
            bottom: 0;
            margin: auto;
        }

        &.open {
            &:after {
                content: "";
                background-image: url($IMG+'parts/close.png');
            }
        }

        &.fixed-open {
            padding: 10px 10px 10px 20px !important;

            &:before,
            &:after {
                display: none !important;
            }
        }
    }

    > dd {
        padding: 10px 10px 10px 20px;
        position: relative;
        word-wrap: break-word;
        left: -20px;
        top: 2px;
        box-shadow: 0px 0px 0 2px $C_BLACK;
        background: $C_LIGHT_GRAY;
        color: white;
        width: calc(100% + 20px);
    }
}

/* ==================
    table
================== */
.table-title {
    text-align: left;
    background-color: $C_RED;
    border: 3px solid #000;
    padding: 8px 5px;
    color: #fff;
    font-weight: bold;
    font-size: 13px;
    vertical-align: middle;
    font-size: 13px;
    line-height: 1.4;
}

th {
    &.w_30 {
        width: 30%;
    }
    &.w_15 {
        width: 15%;
    }
    &.w_45 {
        width: 45%;
    }
}

.table-row td {
    background-color: #fff;
    border: 3px solid #000;
    padding: 10px 5px;
    color: #000;
    font-size: 12px;
    line-height: 1.4;
}


/* ==================
    checkbox
================== */
.checkbox {
    $this: &;
    display: none;

    // テキスト
    + span {
        position: relative;
        padding-left: 24px;

        // 枠
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -1.5px;
            left: 0;
            width: 10px;
            height: 10px;
            border: 3px solid $C_DARK_RED;
        }
    }

    // チェックされている時
    &:checked {
        & + span {
             // テキストの色
            color: $C_DARK_RED;

             // チェックの色
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: -2px;
                left: 5px;
                width: 5px;
                height: 10px;
                transform: rotate(40deg);
                border-bottom: 3px solid $C_DARK_RED;
                border-right: 3px solid $C_DARK_RED;
            }
        }
    }
}