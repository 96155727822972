/* ==================
    stamp
        stamp用のスタイル

    INDEX
        - card
================== */

/* ==================
    card
================== */
// スタンプカード
.stamp-card {
    $this: &;
    $base-top: 57px;
    $base-left: 36px;
    $box-size: 41px;

    display: none;
    position: relative;
    width: 280px;
    height: 185px;
    margin: 15px auto;
    background-image: url($IMG+'stamp/card.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    // スタンプ
    &__stamp {
        position: absolute;
        top: $base-top;
        left: $base-left;
        width: 40px;
        height: 40px;
        padding: 6px;
        transition-duration: 0.2s;
        transform: scale(1,2);
        opacity: 0;

        @for $i from 1 through 10 {
            &:nth-of-type(#{$i}) {
                // top
                @if $i > 5 {
                    top: $base-top + $box-size;
                }
                
                // left
                @if $i > 1 {
                    @if $i < 6 {
                        left: $base-left + ($box-size * ($i - 1));
                    }
                }
                @if $i > 6 {
                    left: $base-left + ($box-size * ($i - 6));
                }

                // delay
                transition-delay: ($i*0.3s);
            }
        }
    }

    // アニメーション
    &.js-active {
        #{$this}__stamp {
            transform: scale(1);
            opacity: 1;
        }
    }

    // テキスト
    &-list {
        position: absolute;
        top: 14px;
        left: 33px;
        
        &__item {
            position: relative;
            color: $C_WHITE;
            line-height: 1.5;
            font-size: 10px;

            &:before {
                content: "";
                width: 6px;
                height: 6px;
                background-color: $C_BLACK;
                left: -11px;
                position: absolute;
                top: 0px;
                bottom: 0;
                margin: auto;
                transform: rotate(45deg);
            }
        }
    }


    &__result {
        display: none;
        font-size: 12px;
        line-height: 1.6;
    }
}