
.movie_thumb {
    width: 280px;
    height: auto;
    margin: auto;

    #player_0,
    #eviry-screen_1 {
	text-align: center!important;
	margin:0 auto!important;
	width: 100%!important;
    }
}

.movie-container {
    $this: &;
    $box-shadow-size: 3px;
    border: 2px solid $C_RED;
    box-shadow: 0 0 0 $box-shadow-size $C_BLACK;
    position: relative;
    width: calc(100% - #{$box-shadow-size*2});
    padding-top: 56.25%;
    margin: auto;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    &--black {
        @extend #{$this};
        background-color: $C_BLACK;
    }
}
.movie-vertical {
    $this: &;
    $box-shadow-size: 3px;
    border: 2px solid $C_RED;
    box-shadow: 0 0 0 $box-shadow-size $C_BLACK;
    position: relative;
    width: calc(100% - #{$box-shadow-size*2});
    padding-top: 169%;
    margin: auto;
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }
}