/* ==================
    members card
        メンバーズカードのスタイル

    INDEX
        - common
================== */

/* ==================
    common
================== */
.members-card {

    &-area {
        position: relative;
        display: block;
        width: 290px !important;
        margin: 0 auto;
    }

    // 情報
    &-info {
        position: relative;

        p {
            position: absolute;
            font-size: 1rem;
            width: 42%;
        }

        &.rank-black p {
            color: #fff;
        }

        &__number {
            bottom: 58px;
            right: 0;
            padding-right: 10px;
        }

        &__name {
            top: 66px;
            right: 0;
            padding-right: 10px;
            line-height: 1.2rem;
        }

        &__since {
            bottom: 44px;
            right: 0;
            padding-right: 10px;
        }

        &__stage {
            bottom: 30px;
            right: 0;
            padding-right: 10px;
        }

        &__img {
            position: absolute;
            top: 61px;
            width: 67px;
            left: 14px;
            height: 83px;
            background-position: center;
            background-size: cover;
        }
    }

}
