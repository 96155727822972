/* ==================
    memory
        memoryページ用のスタイル

    INDEX
        - article
        -archives
================== */

/* ==================
    article
================== */
.memory-article {
    position: relative;
    background-color: $C_BLACK;
    color: $C_WHITE;
    padding: 15px 0;
    box-shadow: 4px 4px $C_RED;

    &:not(:first-child) {
        margin-bottom: 30px;
    }

    .inner {
        padding: 0 15px;
        margin-bottom: 25px;
    }

    .border-title {
        margin-left: 15%;
        width: auto;
    }

    &__time {
        @extend %f-en-serif;
        position: absolute;
        top: 0;
        left: 0;
        width: 15%;
        background-color: #a50000;
        overflow: hidden;
        padding-top: 15%;

        &:before {
            content: "";
            width: 0.5px;
            height: 200%;
            background: rgba($C_WHITE, 0.5);
            position: absolute;
            top: -50%;
            left: -1px;
            transform: rotate(45deg);
            right: 0;
            margin: auto;
        }

        span {
            position: absolute;
            color: $C_WHITE;
            font-size: 5vw;

            &:first-child {
                top: -2px;
                left: 8%;
            }

            &:last-child {
                bottom: -2px;
                right: 8%;
            }
        }
    }

    &__img {
        width: 80%;
        height: auto;
        margin: 20px auto;
    }

    &__tab {
        width: 20px;
        height: 20px;
        position: absolute;
        right: 10px;
        bottom: 10px;

        a {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.c-logo {
    position: absolute;
    right: 0;
    bottom: 8px;
    width: 100px;
    left: 0;
    margin: auto;
}

/* ==================
    archives
================== */

a.archives__year {
    font-size: 1.4rem;
    font-weight: bold;
    background: $C_RED;
    padding: 2px 5px;
    box-sizing: border-box;
    color: $C_WHITE;
    margin-left: 10px;
}

.archives__month {
    margin-left: 15px;
    font-weight: bold;
    letter-spacing: 0.15em;

    a {
        display: inline-block;
        padding: 7px 5px;
    }

    &::before {
        content:" ";
        display:inline-block;
        width: 70px;
        height: 12px;
        background:url(../images/memory/archive_month.png);
        background-size: contain;
        vertical-align: middle;
        background-repeat: no-repeat;
        margin: 0px 5px;

    }
}
