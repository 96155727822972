/* ==================
    badge
        バッチのスタイル

    INDEX
        - top
        - members_card
        - lity
================== */

/* ==================
    top
================== */
// トップのモーダルを開くボタン
.badgebtn {
    position: absolute;
    top: -8px;
    right: -8px;
    width: 40px;
    z-index: 100;
}

// トップのモーダル
.badgemodal {
    // タイトルと枠
    &__title {
        text-align: center;
        padding: 35px 45px 20px;
        margin: 30px;
        border: 5px solid $C_BLACK;
        border-radius: 5px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -23px;
            left: 0;
            right: 0;
            margin: auto;
            width: 187px;
            height: 42px;
            background-image: url($IMG+'badge/title.png');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }

    // 画像自体
    &__item {}
}


/* ==================
    members card
================== */
.badgearea {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 7px 0 17px;
    border-bottom: 1px solid $C_GRAY;

    // 横並びボックス
    &__box {
        display: inline-block;
        width: 20%;
        text-align: center;

        &:nth-of-type(1) {
            width: 25%;
        }

        &:nth-of-type(2) {
            width: 12%;

            img {
                width: 15px;
                vertical-align: bottom;
            }
        }

        &:nth-of-type(3) {
            span {
                display: block;
                width: 80px;
                height: 80px;
                line-height: 2.5;
                border-radius: 100%;
                font-size: 32px;
                background-color: #000;
                color: #fff;
                font-family: 'Rozha One', serif;
            }
        }
    }

    // 下の文言
    &__text {
        text-align: right;
        display: block;
        margin-bottom: 30px;
    }
}


/* ==================
    lity（モーダルプラグイン）
================== */
.lity {
    background-color: rgba($C_WHITE, 0.9) !important;

    // コンテンツの影を消す
    &-content:after {
        box-shadow: none;
    }

    // 閉じるボタン
    &-close {
        text-shadow: none;
        color: $C_BLACK;
    }
}
