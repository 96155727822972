/* ==================
    animation
================== */

// -------------------------
// 波紋
// -------------------------
@keyframes ripple {
    to {
        opacity: 0;
        transform: scale(2.0);
    }
}
