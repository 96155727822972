/* ==================
    footter
================== */
.footer {
    padding: 30px 0;
    background-color: $C_BLACK;
    position: absolute;
    width: 100%;
    height: 90px;
    bottom: 0;
    text-align: center;
    overflow: hidden;

    .copyright {
        color: $C_WHITE;
    }
}

.fixed-footer {
    position: fixed;
    bottom: 0;
    display: block;
    width: 100%;
    padding: 0 10px 20px;
    z-index: 999;
    transform: translateY(100px);
    transition: 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

    &.active {
        transform: translateY(0);
    }
}

// サウンドのon/offボタン
.sound-btn {
    transition: background-color 0.3s;

    span,
    i {
        transition: color 0.3s;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        z-index: -10;
        width: 100%;
        height: 100%;
        top: -1px;
        left: -1px;
        border-radius: 3px;
        transform: translate3d(0, 0, 0);
        transition: background-color 0.3s;
    }


    // アクティブ時（topのメンバーズメニューが表示されているとき）
    &.active {

        background: $C_GRAY;
        border-color: $C_GRAY;

        &:before,
        &:after {
            background: $C_GRAY;
            border: 1px solid $C_GRAY;
        }

        // onのとき
        &.is-on {
            background: $C_BLUE;
            border-color: $C_BLUE;

            span,
            i {
                color: $C_WHITE;
            }


            &:before,
            &:after {
                background: $C_BLUE;
                border: 1px solid $C_BLUE;
            }
        }

        // オーラのアニメーションをつける
        &:before {
            animation: aura 1s ease-out infinite;
        }

        &:after {
            animation: aura 1s ease-out 0.5s infinite;
        }
    }

}

@keyframes aura {
    0% {
        transform: scale(0.95);
        opacity: 1;
    }
    90% {
        opacity: 0.1;
    }
    to {
        transform: scale(1.2, 1.5);
        opacity: 0;
    }
}

.pagetop {
    width: 59px;
    height: auto;
    position: absolute;
    bottom: 60px;
    right: 15px;
}
