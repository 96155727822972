/* ==================
    special
        specialページ内だけで使う

    INDEX
        - gallery(仮)

================== */

.gallery-list__item__img {
    position: relative;
    width: 100%;
    padding-top: 74.5%;; 
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 8px;
}

