/* ==================
    common

    INDEX
        - wrapper
        - content
        - aem-post
        - table
        - category
        - movie

================== */

/* ==================
    wrapper
================== */
.wrapper {
    overflow: hidden;
    padding-bottom: 120px;

    &.top {
        padding-bottom: 0;
    }
}


/* ==================
    content
================== */
.content {
    &-wrap {
        margin: 25px 0 50px;

        &.top {
            margin: 25px 0 0;
        }

        .inner {
            padding: 0 15px;
        }
    }

    &-title {
        margin-bottom: 20px;

        img {
            margin-left: calc(10% + 20px);
            width: 90%;
        }
    }
}


/* ==================
    aem-post
================== */
// newsなどの投稿記事を囲むクラス
.aem-post {
    word-wrap: break-word;
    overflow-wrap: break-word;
    letter-spacing: 1px;

    img {
        max-width: 100% !important;
        height: auto !important;

        &.blank-img {
            height: 100% !important;
        }
    }

    strong {
        font-weight: bold;
    }

    .movie-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56.25%;

        iframe {
            position: absolute;
            top: 0;
            right: 0;
            width: 100% !important;
            height: 100% !important;
        }
    }

    span {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
    }

    span.js-protect {
        width: 75%;
        margin: auto;
        display: block !important;
    }

    h1 {
        font-size: 2em;
    }

    h2 {
        font-size: 1.8em;
    }

    h3 {
        font-size: 1.6em;
    }

    h4 {
        font-size: 1.4em;
    }

    h5 {
        font-size: 1.2em;
    }

    h6 {
        font-size: 1em;
    }
}


/* ==================
    table
================== */
.l4-r6 {
    th {
        width: 40%;
    }

    td {
        width: 60%;
    }
}

.l3-r7 {
    th {
        width: 30%;
    }

    td {
        width: 70%;
    }
}

.pb-10 {
    th,
    td {
        padding-bottom: 10px;
    }
}


/* ==================
    category
================== */
.accordion {
    position: relative;
    border: 2px solid $C_GRAY;
    border-radius: 3px;
    font-size: 1.5rem;
    letter-spacing: 2.4px;

    input {
    	display: none;
    }

    &-title {
        transition: 0.3s;
        text-align: center;
        background: $C_WHITE;
        padding: 14px;
        display: block;

        &:before {
            content: "";
            position: absolute;
            top: 18px;
            right: 15px;
            border-style: solid;
            border-color: $C_BLACK transparent transparent transparent;
            border-width: 5px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 16px;
            right: 15px;
            border-style: solid;
            border-color: $C_WHITE transparent transparent transparent;
            border-width: 5px;
        }
    }

    &-list {
        &__item {
            display: block;
            width: 100%;
            text-align: center;
            max-height: 0;
            overflow-y: hidden;
            transition: 0.5s;

            a,
            span {
                padding: 10px;
                display: block;
            }
        }

        &__item.active {
            background: $C_BLUE;

            a,
            span {
                color: $C_WHITE;
            }
        }
    }

    &.active {

        .accordion-title {
            background: $C_GRAY;

            &:before {
                top: 12px;
                border-color: transparent transparent $C_BLACK transparent;
            }

            &:after {
                top: 14px;
                border-color: transparent transparent $C_GRAY transparent;
            }
        }

        .accordion-list__item {
            max-height: 46px;
            opacity: 1;
        }
    }
}

.contants-category-logo {
    width: 14px;
    vertical-align: top;
    margin: 2px -2px;
}
