/* ==================
    fournune
        おみくじのスタイル

    INDEX
        - wellcamepage
        - result
================== */

/* ==================
    wellcamepage
================== */
.fortune-btn-area {
    position: relative;
    margin: 20px 0 25px;
}

.torii-area {
    position: relative;
}

.torii-img {
    transition: 1s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transform-origin: bottom center;
    transform: scale(1);

    &.js-active {
        transform: scale(3);
    }
}

hr.black {
    width: 200%;
    margin: auto;
    display: block;
    position: absolute;
    left: -50%;
    border: none;
    border-top: 1px solid black;
    bottom: 2px;
}


/* ==================
    result
================== */
.today-data {
    position: relative;
    width: 40%;
    margin: 30px auto;
    transition: 1s;
    opacity: 0;

    &.js-active {
        opacity: 1;
    }

    &__year {
        @extend %f-en;
        text-align: center;
        font-size: 2.8rem;
        position: absolute;
        top: -40px;
        right: 0;
        left: 0;
        margin: auto;
    }

    &__day {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
    }

    &__month {
        z-index: 1;
    }

    &__week {
        @extend %f-en;
        position: relative;
        text-align: center;
        color: $C_WHITE;
        background: $C_BLACK;
        margin: 3px 0 20px;
        font-size: 1.6rem;
        transition: 1s;
        transform: translateX(-200vw);
        line-height: 1.6;

        &.js-active {
            transform: translateX(0);
        }

        &:before {
            content: "";
            position: absolute;
            width: 200vw;
            height: 100%;
            top: 0;
            left: -50vw;
            background-color: $C_BLACK;
            z-index: -1;
        }
    }

    // これはindex.tplの方で使っているやつ
    &.type-2 {
        position: absolute;
        width: 37%;
        margin: auto;
        transition: 0s;
        top: 35vw;
        right: 0;
        left: 3px;
        opacity: 1;
    }

    &.type-2 &__year {
        top: -20px;
    }

    &.type-2 &__day {
        top: 20px;
    }

    &.type-2 &__month {
        margin-top: 20px;
    }

    &.type-2 &__week {
        transform: translateX(0);
        transition: 0;
        width: 140%;
        left: -20%;

        &::before {
            display: none;
        }
    }
}

.kuji-area {
    width: 80%;
    margin: auto;
    display: none;
}

.kuji-result-btn {
    height: 50px;

    a {
        display: none;
    }
}

.kuji-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $C_BLACK;
    pointer-events: none;
    z-index: 99999999999;
    opacity: 0;
    transition: 1s;

    &.js-active {
        opacity: 1;
    }
}