/* ==================
    menu
================== */
// 左上のメニュー
.global-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;

    // 開閉ボタン
    .toggle-menu {
        width: 57px;
        height: 57px;
        background-image: url($IMG+"parts/circle.png");
        background-size: contain;
        margin: 8px;
        position: relative;
        z-index: 1000;
        transition: 0.2s;
        opacity: 0;
        pointer-events: none;

        // 表示
        &.js-active {
            opacity: 1;
            pointer-events: auto;
        }

        span {
            background: $C_WHITE;
            width: 25px;
            height: 3px;
            display: block;
            position: absolute;
            right: 0;
            left: 0;
            margin: auto;
            top: 35px;

            &:nth-child(1) {
                top: 19px;
            }

            &:nth-child(2) {
                top: 28px;
            }

            &:nth-child(3) {
                top: 37px;
            }
        }
    }

    // メニュー本体
    &-list {
        position: fixed;
        top: 0;
        left: -30vw;
        width: 30vw;
        height: 100%;
        overflow-y: scroll;
        transition: 0.5s;
        -webkit-overflow-scrolling: touch;

        &__item {
            display: table;
            width: 30vw;
            height: 30vw;
            background-color: $C_DARK_LIGHT_RED;

            // 開閉前の位置
            transform-origin: 0;
            transition: transform 0.3s;
            transform: rotateY(180deg);

            a {
                display: table-cell;
                vertical-align: middle;
                text-align: center;

                div {
                    display: block;
                    color: $C_WHITE;
                    line-height: 1.2;
                }

                div.menu-home {
                    img {
                        width: 40px;
                        height: auto;
                    }
                }

                span {
                    color: $C_WHITE;
                }
            }

            // 柄付き
            &:nth-child(3),
            &:nth-child(7),
            &:nth-child(9),
            &:nth-child(12) {
                background-image: url($IMG+"parts/pattern_2.png");
                background-size: cover;
                background-size: 50px;
            }

            // 濃い色
            &:nth-child(2),
            &:nth-child(5),
            &:nth-child(9),
            &:nth-child(10) {
                background-color: $C_DARK_RED;
            }
        }

        &.js-active {
            z-index: 10000;

            .global-menu-list__item {
                @for $i from 1 through 12 {
                    &:nth-child(#{$i}) {
                        animation: 0.2s openMenu #{$i * 0.1}s forwards;
                    }
                }
            }
        }

        // 開く直前
        &.js-before {
            left: 0;
        }

        // 閉じる直前
        &.js-after {
            transform: translateX(-100%);
        }
    }

    // メニューの下に引くやつ
    &-overlay {
        display: none;
        width: 100vw;
        height: 120vh;
        position: absolute;
        top: -10vw;
        left: 0;
        background-color: $C_WHITE;
        opacity: 0.5;
        z-index: -1;
    }
}


@keyframes openMenu {
    0% {
        transform: rotateY(180deg)
    }
    100% {
        transform: rotateY(0)
    }
}
