// ==================
//    variable
// ==================

// ==================
//    color
// ==================
// 基本
$C_WHITE: #fff;
$C_RED: #a50000;
$C_DARK_RED: #750505;
$C_DARK_LIGHT_RED: #8f0606;
$C_YELLOW: #e4935b;
$C_BLUE: #6699cc;
$C_LIGHT_BLUE: #4a93e2;
$C_GRAY: #454545;
$C_LIGHT_GRAY: #bdbdbd;
$C_DARK_GRAY: #454545;
$C_BLACK: #000;
$C_LIGHT_BLACK: #5c5c5c;
$C_GREEN: #56ac91;
$C_PINK: #fb646e;
$C_LIGHT_PINK: #ffa69e;
$C_DARK_PINK: #e5776e;
$C_PURPER: #8989c6;

// メイン
$C_MAIN: $C_BLACK;
$C_SUB1: $C_BLACK;
$C_SUB2: $C_BLACK;
$C_SUB3: $C_BLACK;

// パーツ
$C_BG: $C_WHITE;
$C_FONT: $C_BLACK;
$C_LINK_FONT: $C_RED;
$C_BORDER: $C_GRAY;


// ==================
//    path
// ==================
$IMG: "../images/";


// ==================
//    font
// ==================
// メインフォント
$MAIN_FONT_FAMILY: "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

// はんなり明朝、さわらび明朝
$JP_FONT_FAMILY: "Hannari", "Sawarabi Mincho", "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

// Radley
$EN_SERIF_FONT_FAMILY: serif, "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
$EN_FONT_FAMILY: 'Radley', serif, "Yu Gothic Medium", "游ゴシック Medium", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
