/* ==================
    special
        specialページ内だけで使う

    INDEX
        - katsumoku

================== */

/* ==================
    katsumoku
    刮目相待ライブ施策
================== */
.special-katsumoku {

    h2 {
        img {
            width: 100%;
            max-width: 280px;
        }
    }

    .checked {
        font-family: 'Orbitron', sans-serif;

        p {
            line-height: 1.3;
            font-size: 16px;
        }

        .small {
            font-size: 14px;
        }        

        .x-large {
            font-size: 35px;
            font-weight: bold;
        }
    }

    .badge {
        img {
            width: 150px;
        }
    }



}